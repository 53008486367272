// use this line to import all the bootstrap JS
import 'bootstrap';

var jQueryBridget = require('jquery-bridget');
var Isotope = require('isotope-layout');


jQueryBridget('isotope', Isotope, jQuery);

var jQueryMatchHeight = require('jquery-match-height');

window.$ = $;

import "./js/navbar";
import "./js/grid";
import './js/aos';
import "./js/bgCarousel";

// or, specify specific plugins
// import 'bootstrap/js/src/alert.js';
// import 'bootstrap/js/src/button.js';
// import 'bootstrap/js/src/carousel.js';
// import 'bootstrap/js/src/collapse.js';
// import 'bootstrap/js/src/dropdown.js';
// import 'bootstrap/js/src/index.js';
// import 'bootstrap/js/src/modal.js';
// import 'bootstrap/js/src/popover.js';
// import 'bootstrap/js/src/scrollspy.js';
// import 'bootstrap/js/src/tab.js';
// import 'bootstrap/js/src/toast.js';
// import 'bootstrap/js/src/tools';
// import 'bootstrap/js/src/tooltip.js';
// import 'bootstrap/js/src/util.js';



// Then pull in our scss
import './scss/main.scss';