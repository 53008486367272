(function($){
    $.fn.bgCarousel = function(options) {
        options = $.extend({duration: 4500, activeClass: 'active', itemClass: 'item', parentClass: 'bg-carousel'}, options);
        var self = this;
        var items = this.find('.' + options.itemClass);

        window.setInterval(function(){
            var nextItem = $(items).filter('.' + options.activeClass).next('.' + options.itemClass);
            if (nextItem.length <= 0) {
                nextItem = $(items).first();
            }
            items.removeClass(options.activeClass);
            nextItem.addClass(options.activeClass);
        }, options.duration);

        return this;
    }
})(jQuery);