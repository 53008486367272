(function($){
    function concatValues(obj) {
        var value = '';
        for ( var prop in obj ) {
            value += obj[ prop ];
        }
        return value;
    };

    function getUrlVars() {
        var vars = [], hash;
        if (window.location.href.indexOf('?') >= 1)
        {
            var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for(var i = 0; i < hashes.length; i++)
            {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
        }
        return vars;
    }

    function gridInit() {
        if ($('.staff-grid').length) {
            staffGridInit();
        }

        var facilityGrid = null;

        if ($('.facilities-grid').length) {
            facilityGrid = facilitiesInit();
        }

        if ($('.branches-grid').length) {
            branchesInit(facilityGrid);
        }


    }

    function staffGridInit() {

        var staffGrid = $('.staff-grid').isotope({
            itemSelector: '.grid-item',
            layoutMode: 'fitRows',
            percentPosition: true
        });

        staffGrid.isotope('on', 'arrangeComplete', function(filteredItems){
            if (filteredItems.length <= 0) {
                $('#staff-no-results').show();
            } else {
                $('#staff-no-results').hide();
            }
        });

        var filters = {};

        $('[data-staff-filter]').each(function(i, group){
            var filterGroup = $(this).attr('data-staff-filter');

            $(group).find('input').change(function(e){
                filters[filterGroup] = $(this).val();
                var filterValue = concatValues(filters);

                staffGrid.isotope({
                    filter: filterValue
                });
            });
        });

        var urlVars = getUrlVars();
        for (var key in urlVars) {
            if (urlVars.hasOwnProperty(key)) {
                var value = urlVars[key];
                $('[data-staff-filter=' + key + ']').each(function(i, group){
                    $(this).find('#' + value).click();
                });
            }
        }
    }

    function branchesInit(facilityGrid) {
        var branches = $('.branch-item');
        var facilityGrid = facilityGrid;

        var filterBranches = function(filter) {
            if (branches.filter(filter).length) {
                branches.addClass('d-none');
                branches.filter(filter).removeClass('d-none');
            } else {
                branches.removeClass('d-none');
            }
            facilityGrid.isotope('layout');
        };

        $('[data-branch-filter]').each(function(i, branch){
            var filterGroup = $(this).attr('data-branch-filter');

            $(branch).find('input').change(function(e){
                filterBranches($(this).val());
            });
        });

        var loadFromUrl = function() {
            var urlVars = getUrlVars();
            for (var key in urlVars) {
                if (urlVars.hasOwnProperty(key)) {
                    var value = urlVars[key];

                    if (key === 'branches') {
                        filterBranches('.' + value);
                    }

                    $('[data-branch-filter='+ key +']').each(function(i, branch){
                        var branch = $(this).find('#' + value);
                        if (branch.length) {
                            $(this).find('label').removeClass('active');
                            branch.parent('label').addClass('active');
                        }
                    });
                }
            }
        }

        loadFromUrl();
    }

    function facilitiesInit() {
        var facilitiesFilters = {
            'species': '.species-general',
        };

        var facilitiesGrids = $('.facilities-grid').isotope({
            itemSelector: '.facility',
            layoutMode: 'fitRows',
            percentPosition: true,
            filter: concatValues(facilitiesFilters)
        });

        var updateUi = function() {
            for (var filter in facilitiesFilters) {
                //remove checked prop
                $('[data-facility-filter="' + filter + '"]').find('label').removeClass('active');//.find('input').removeProp('checked');
                $('[data-facility-filter="' + filter + '"]').find('[value="' + facilitiesFilters[filter] + '"]').parent('label').addClass('active');//.find('input').prop('checked', true);

                $('.branch-item').each(function(i, branch){
                    var branch = $(branch);

                    branch.find('.species-overview').removeClass('d-flex').addClass('d-none');
                    branch.find('.species-overview' + facilitiesFilters[filter]).addClass('d-flex').removeClass('d-none');

                    if (branch.find('.species-overview' + facilitiesFilters[filter]).length <= 0) {
                        branch.find('.species-overview.species-general').addClass('d-flex').removeClass('d-none');
                    }
                });
            }
        };

        facilitiesGrids.isotope('on', 'arrangeComplete', function(filteredItems){
            updateUi();
        });

        $('[data-facility-filter]').each(function(i, facility){
            var filterGroup = $(this).attr('data-facility-filter');

            $(facility).find('input').change(function(e){
                facilitiesFilters[filterGroup] = $(this).val();
                var filterValue = concatValues(facilitiesFilters);

                facilitiesGrids.isotope({
                    filter: filterValue
                });
            });
        });

        var loadFromUrl = function() {
            var urlVars = getUrlVars();
            for (var key in urlVars) {
                if (urlVars.hasOwnProperty(key)) {
                    var value = urlVars[key];
                    if (key === 'species') {
                        facilitiesFilters.species = '.' + value;
                        var filterValue = concatValues(facilitiesFilters);
                        facilitiesGrids.isotope({
                            filter: filterValue
                        });
                    }
                }
            }
        }

        updateUi();
        loadFromUrl();

        return facilitiesGrids;
    }

    $(document).ready(function(){
        $.fn.matchHeight._afterUpdate = function(event, groups) {
            gridInit();
        };

        $('.match-height').matchHeight({byRow: false});
    });

})(jQuery);