(function($){
    $(document).ready(function(){
        window.onscroll = function() {
            checkNavbar();
        };

        var _navbar = document.getElementById('site-nav');
        var _siteInfo = document.getElementById('site-info');

        function checkNavbar() {
            if (_navbar.offsetTop > (_siteInfo.clientHeight + 1)) {
                _navbar.classList.add('stuck');
            } else {
                _navbar.classList.remove('stuck');
            }
        };

        checkNavbar();
    });
})(jQuery);